<template>
  <v-card class="mx-auto" :loading="loading" :disabled="loading">
    <v-card-title>
      <v-row>
        <v-col cols="12" xs="12" md="8">
          <BtnCircle
            tLabel="Atrás"
            tPosition="bottom"
            icon="mdi-arrow-left"
            color="grey darken-3"
            size="x-small"
            :to="{
              name: `${page_route}${store_mode ? '' : '.view'}`,
              params: { id: id },
            }"
          />
          <span class="text-h6 ml-1" v-text="$route.meta.title" />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-form ref="form_data" lazy-validation>
        <v-row>
          <v-col cols="12">
            <v-card class="mx-auto">
              <v-card-title>
                <h2 class="text-caption" v-text="'GENERAL'" />
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-row>
                  <v-col cols="12" xs="12" md="12">
                    <v-text-field
                      label="Nombre*"
                      v-model="data.provider_group"
                      :rules="rules.text100Required"
                      type="text"
                      dense
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card class="mx-auto">
              <v-card-title>
                <h2
                  class="text-caption"
                  v-text="`PROVEEDORES (${filterProviders.length})`"
                />
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-row dense>
                  <v-col cols="12" xs="12" md="3">
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Buscar..."
                      single-line
                      hide-details
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-autocomplete
                      label="Tipo*"
                      v-model="provider_type_id"
                      :items="provider_types"
                      item-value="id"
                      :item-text="(i) => i.provider_type"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-autocomplete
                      label="Estado*"
                      v-model="entity_id"
                      :items="entities"
                      item-value="id"
                      :item-text="(i) => i.entity"
                      :loading="entities_loading"
                      v-on:change="getMunicipalities()"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-autocomplete
                      label="Municipio*"
                      v-model="municipality_id"
                      :items="municipalities"
                      item-value="id"
                      :item-text="(i) => i.municipality"
                      :loading="municipalities_loading"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="12">
                    <v-btn block small color="primary" @click="checkAll">
                      Seleccionar todos los visibles
                      <v-icon right v-text="'mdi-check'" />
                    </v-btn>
                  </v-col>
                  <v-col cols="12">
                    <small>*APT = Alta Apertura Terminada</small>
                  </v-col>
                  <v-col cols="12" xs="12" md="12">
                    <v-data-table
                      :headers="headers"
                      :items="filterProviders"
                      item-key="id"
                      :search="search"
                      :loading="providers_loading"
                      dense
                    >
                      <template v-slot:item.memberGroup="{ item }">
                        <v-checkbox v-model="item.member" class="my-0 py-0" />
                      </template>

                      <template v-slot:item.discharge="{ item }">
                        <v-checkbox
                          v-if="item.member"
                          v-model="item.opening_discharge"
                          class="my-0 py-0"
                        />
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" class="text-right">
            <BtnCircle
              :tLabel="store_mode ? 'Crear' : 'Editar'"
              tPosition="top"
              :icon="store_mode ? 'mdi-plus' : 'mdi-pencil'"
              :color="store_mode ? 'success' : 'info'"
              size="small"
              :click="handleSubmit"
              :loading="loading"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  msgConfirm,
  msgAlert,
  rules,
} from "../../control";
import { catalog } from "../../requests/catalogRequest";
import { storeUpdate, show, index } from "../../requests/pageRequest";
import BtnCircle from "../../components/BtnCircle.vue";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    BtnCircle,
    FaqDlg,
  },
  data() {
    return {
      page_route: "redes.proveedores",
      id: this.$route.params.id ? this.$route.params.id : 0,
      store_mode: this.$route.params.id ? false : true,
      login: this.$store.getters.getLogin,
      loading: false,
      data: {
        provider_group: "",
        provider_in_groups: [],
      },
      group: [],
      search: "",
      headers: [
        { text: "Red", filterable: false, value: "memberGroup", width: "40px" },
        {
          text: "APT",
          filterable: false,
          value: "discharge",
          width: "40px",
        },
        { text: "Nombre", filterable: true, value: "name" },
        { text: "Tipo", filterable: true, value: "provider_type" },
        { text: "Estado", filterable: true, value: "entity" },
        { text: "Municipio", filterable: true, value: "municipality" },
      ],
      rules: rules(),
      providers_loading: true,
      entity_id: null,
      entities: [],
      entities_loading: true,
      municipality_id: null,
      municipalities: [],
      municipalities_loading: false,
      entity_id_filter: null,
      municipality_id_filter: null,
      provider_types: [],
      provider_type_id: null,
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    handleSubmit() {
      if (this.$refs.form_data.validate()) {
        this.$swal
          .fire(
            msgConfirm(
              `¿Confirma la ${
                this.store_mode ? "creación" : "edición"
              } del registro?`
            )
          )
          .then((response) => {
            if (response.isConfirmed) {
              this.loading = true;
              storeUpdate(
                "provider/groups",
                this.login.token,
                this.id,
                this.data,
                this.login.id
              ).then((response) => {
                this.$swal.fire(
                  msgAlert(
                    response.success ? "success" : "error",
                    response.message
                  )
                );

                response.success
                  ? this.$router.push({
                      name: `${this.page_route}${
                        this.store_mode ? "" : ".view"
                      }`,
                      params: { id: this.id },
                    })
                  : console.log(response.message);
              });

              this.loading = false;
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    memberSelected(item) {
      alert("holid");
    },
    defaultMunicipalities() {
      this.municipalities.push({
        id: null,
        municipality: "TODOS",
        active: 1,
        entity_id: this.entity_id,
      });
    },
    getMunicipalities() {
      this.municipality_id = null;

      if (this.entity_id) {
        this.municipalities_loading = true;

        index("municipalities/" + this.entity_id, this.login.token, []).then(
          (response) => {
            this.municipalities = response.data;
            this.defaultMunicipalities();
            this.municipalities_loading = false;
          }
        );
      } else {
        this.municipalities = [];
        this.defaultMunicipalities();
      }
    },
    checkAll() {
      for (var i = 0; i < this.filterProviders.length; i++) {
        this.filterProviders[i].member = true;
      }
    },
  },
  computed: {
    filterProviders() {
      return this.data.provider_in_groups.filter((provider) => {
        let condition1 = this.provider_type_id
          ? provider.provider_type_id === this.provider_type_id
          : true;
        let condition2 = this.entity_id
          ? provider.entity_id === this.entity_id
          : true;
        let condition3 = this.municipality_id
          ? provider.municipality_id === this.municipality_id
          : true;
        return condition1 && condition2 && condition3;
      });
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    catalog("entities", this.login.token).then((response) => {
      this.entities = response;
      this.entities.push({
        id: null,
        entity: "TODOS",
        abbreviation: "TODOS",
        active: 1,
      });
      this.entity_id = null;
      this.entities_loading = false;
    });
    catalog("provider_types", this.login.token).then((response) => {
      this.provider_types = response;
      this.provider_types.push({
        id: null,
        provider_type: "TODOS",
        active: 1,
      });
    });

    this.defaultMunicipalities();

    if (!this.store_mode) {
      this.loading = true;

      show("provider/groups", this.login.token, this.id).then((response) => {
        this.data = response;
        this.providers_loading = false;
        this.loading = false;
      });
    } else {
      index("providers", this.login.token, []).then((response) => {
        this.data.provider_in_groups = response.data;
        this.providers_loading = false;
      });
    }
  },
};
</script>